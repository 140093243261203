import { App } from "./App"
import { BrowserRouter } from "react-router-dom"
import { createRoot } from "react-dom/client"
import "./utils/i18n"

const container = document.getElementById(`root`)
const root = createRoot(container!)
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
)
