import { Box, ButtonBase, Stack, Typography, useTheme } from "@mui/material"
import { MouseEventHandler } from "react"

export interface AnswerComponentI {
  name: string
  className?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
}
export function AnswerComponent({ onClick, name, className }: AnswerComponentI) {
  const theme = useTheme()

  return (
    <ButtonBase
      onClick={onClick}
      className={className}
      sx={{
        backgroundColor: `white`,
        border: `1px solid`,
        borderColor: theme.palette.primary.main,
        borderRadius: `32px`,
        minHeight: 50,
        padding: `5px`,
        width: `100%`,
        position: `relative`,
        boxSizing: `border-box`,
        color: theme.palette.primary.main,
        display: `flex`,
      }}
    >
      <Stack
        spacing={2}
        direction={`row`}
        sx={{
          alignContent: `center`,
          width: `100%`,
          pl: 2,
          pr: 2,
          alignItems: `center`,
        }}
      >
        <Box sx={{ fontSize: 18, textAlign: `center`, width: `100%` }}>
          <Typography sx={{ fontSize: 16 }} variant={`button`}>
            {name}
          </Typography>
        </Box>
        {/* } */}
      </Stack>
    </ButtonBase>
  )
}
