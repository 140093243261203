import { Routes, Route, useLocation, Navigate } from "react-router-dom"
import { AutoDiagWrapper as AutoDiag } from "./auto-diag/auto-diag"
import { Product } from "./product/product"
import { GetParams } from "@homeserve/od-funnel-lib"
import { SearchView } from "./search/search"

export function Views() {
  const location = useLocation()
  GetParams(location)

  return (
    <>
      <Routes>
        <Route path="/product/*" element={<Product />} />
        <Route path="/diagnostic/:domain" element={<AutoDiag />} />
        <Route path="/diagnostic" element={<AutoDiag />} />
        <Route path="/diagnostic/:domain/*" element={<AutoDiag />} />
        <Route path="/search" element={<SearchView />} />
        <Route path={`/`} element={<Navigate to={`/search`} replace={true} />} />
        <Route path={`*`} element={<Navigate to={`/search`} replace={true} />} />
      </Routes>
    </>
  )
}
