import { ReactNode } from "react"
import { Box, Container } from "@mui/material"

export interface ILayout {
  children: ReactNode
}

export function Layout({ children }: ILayout) {
  return (
    <Container maxWidth={`lg`} sx={{ display: `flex`, justifyContent: `center` }}>
      <Box sx={{ width: `100%`, maxWidth: `1000px` }}>
        <Box style={{ padding: `16px 0`, marginTop: 0 }}>{children}</Box>
      </Box>
    </Container>
  )
}
