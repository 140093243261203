import PropTypes from "prop-types"
import { useMemo } from "react"
// material
// import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme, StyledEngineProvider } from "@mui/material/styles"
//
import { shape } from "./shape"
import { palette } from "./palette"
import { typography } from "./typography"
import { ComponentsOverrides } from "./overrides"
import { customShadows, shadows } from "./shadows"
import { GlobalStyles } from "./globalStyles"
// import {Button} from "@mui/material";

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node,
}

export function ThemeConfig({ children }) {
  const themeOptions = useMemo(
    () => ({
      palette,
      shape,
      typography,
      shadows,
      customShadows,
    }),
    [],
  )

  const theme = createTheme(themeOptions)
  theme.components = ComponentsOverrides(theme)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {/* <CssBaseline /> */}
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
