import { useContext, useEffect } from "react"
import { useTheme } from "@mui/material/styles"
import { Box, ButtonBase, Stack, Typography } from "@mui/material"
import { useProducts, AppContext } from "@homeserve/od-funnel-lib"

interface FavoritesProps {
  favoriteIds: string | string[]
}

export function FavoritesList({ favoriteIds }: FavoritesProps) {
  useProducts()
  const { state, dispatch } = useContext(AppContext)
  const products = state.products.data || []
  const theme = useTheme()

  useEffect(() => {
    window.scrollTo({ behavior: `smooth`, top: 0 })
  }, [])

  function SetContext(productId?: number) {
    if (!productId) return
    dispatch({
      type: `set_product_context`,
      payload: {
        id: productId,
        price: ``,
        price20: ``,
        image: ``,
        name: ``,
        category: ``,
        description: ``,
        inclusion: ``,
        exclusion: ``,
        condition: ``,
        details: ``,
        jobCinematic: ``,
        jobContext: ``,
        jobWorkforce: ``,
        jobWorkforce20: ``,
        jobWorkforceTaxFree: ``,
        jobWorkforceTax10: ``,
        jobWorkforceTax20: ``,
        callOut: ``,
        callOut20: ``,
        callOutTaxFree: ``,
        callOutTax10: ``,
        callOutTax20: ``,
        spareParts: ``,
        sparePartsTaxFree: ``,
        jobDuration: ``,
        cancellationFees: ``,
        spareParts20: ``,
        sparePartsTax20: ``,
        sparePartsTax10: ``,
        totalHt: ``,
        totalTva10: ``,
        totalTva20: ``,
        houseAgeDependant: true,
        customerVatRate: ``,
        isVatRateHouseAgeDependant: false,
        idTempo: ``,
        noweb: [],
        traderInstructions: ``,
        domain: {
          name: ``,
        },
      },
    })
    window.location.href = `/product`
  }

  const favoriteIdsArray = Array.isArray(favoriteIds) ? favoriteIds : favoriteIds.split(`,`)
  const filteredProducts = products.filter(product => favoriteIdsArray.includes(String(product.id)))

  return (
    <Stack direction="row" justifyContent="center" sx={{ flexWrap: `wrap` }}>
      <Box sx={{ width: `100%`, maxWidth: 450 }}>
        <Typography fontSize={20} fontWeight={700} mb={2}>
          Les plus fréquents
        </Typography>
        {filteredProducts.map(product => (
          <ButtonBase
            key={product.id}
            onClick={() => {
              SetContext(product?.id)
            }}
            sx={{
              backgroundColor: `white`,
              border: `1px solid`,
              borderColor: theme.palette.grey[400],
              boxShadow: `0px 2px 6px 0px rgba(30,30,30,0.12)`,
              borderRadius: `16px`,
              fontSize: `16px`,
              minHeight: 50,
              padding: `5px`,
              width: `100%`,
              position: `relative`,
              boxSizing: `border-box`,
              color: theme.palette.text.primary,
              display: `flex`,
              mb: 2,
              fontFamily: `Nunito`,
            }}
          >
            {product.name}
          </ButtonBase>
        ))}
      </Box>
    </Stack>
  )
}

export function Favorites() {
  const favorites = process.env.REACT_APP_FAVORITES_PRODUCT || ``

  return <FavoritesList favoriteIds={favorites} />
}
