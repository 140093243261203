// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import {
  TableCell,
  TableRow,
  CircularProgress,
  TextField,
  Box,
  Typography,
  InputAdornment,
  ButtonBase,
} from "@mui/material"
import { AppContext, useConfig, useSearchProduct } from "@homeserve/od-funnel-lib"
import { BtnBack, BtnNext, Layout } from "../../components"
import { Search, ArrowBack } from "@mui/icons-material"
import { Favorites } from "./favorites"

export const SearchView: React.FC = () => {
  const config = useConfig()
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [query, setQuery] = useState<string>(``)
  const [page, setPage] = useState<number>(1)
  // const [rowsPerPage, setRowsPerPage] = useState<number>(500)
  const rowsPerPage: number = 500

  const { data, loading, error } = useSearchProduct(query, page, rowsPerPage)

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value
    setQuery(newQuery)
    setPage(1) // Reset to first page when query changes
  }

  useEffect(() => {
    window.scrollTo({ behavior: `smooth`, top: 0 })
  }, [])

  const moveBack = () => {
    window.location.href = config.tempoUrl + `work/${workId}/job/${tempJobId}`
  }
  const moveNext = () => {
    navigate(`/diagnostic`)
  }
  const { state, dispatch } = useContext(AppContext)
  const tempJobId = state.internalRef.tempoJobId
  const workId = state.internalRef.workId
  function SetContext(productId?: number) {
    if (!productId) return
    dispatch({
      type: `set_product_context`,
      payload: {
        id: productId,
        price: ``,
        price20: ``,
        image: ``,
        name: ``,
        category: ``,
        description: ``,
        inclusion: ``,
        exclusion: ``,
        condition: ``,
        details: ``,
        jobCinematic: ``,
        jobContext: ``,
        jobWorkforce: ``,
        jobWorkforce20: ``,
        jobWorkforceTaxFree: ``,
        jobWorkforceTax10: ``,
        jobWorkforceTax20: ``,
        callOut: ``,
        callOut20: ``,
        callOutTaxFree: ``,
        callOutTax10: ``,
        callOutTax20: ``,
        spareParts: ``,
        sparePartsTaxFree: ``,
        jobDuration: ``,
        cancellationFees: ``,
        spareParts20: ``,
        sparePartsTax20: ``,
        sparePartsTax10: ``,
        totalHt: ``,
        totalTva10: ``,
        totalTva20: ``,
        houseAgeDependant: true,
        customerVatRate: ``,
        isVatRateHouseAgeDependant: false,
        noweb: [],
        traderInstructions: ``,
        idTempo: ``,
        domain: {
          name: ``,
        },
      },
    })
    window.location.href = `/product`
  }

  return (
    <Box>
      <Box
        sx={{
          display: `flex`,
          height: `56px`,
          borderBottom: `1px solid`,
          borderColor: theme.palette.grey[400],
          padding: `6px 20px`,
          boxSizing: `border-box`,
        }}
      >
        <BtnBack onClick={moveBack} startIcon={<ArrowBack />} />
      </Box>
      <Layout>
        <Typography fontSize={24} mb={2}>
          Requalification
        </Typography>
        <TextField
          placeholder={`Rechercher`}
          variant="outlined"
          value={query}
          onChange={handleQueryChange}
          InputProps={{
            sx: { borderRadius: 32 },
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          sx={{ width: `100%`, height: `48px` }}
          style={{ marginBottom: `16px` }}
        />

        <Box>
          {data.data && data.data.length > 0 ? (
            <Box>
              {loading ? (
                <CircularProgress />
              ) : error ? (
                <div>
                  {t(`Error`)}: {error}
                </div>
              ) : (
                <Box>
                  {data.data && data.data.length > 0 ? (
                    data.data.map(product => (
                      <ButtonBase
                        key={product.id}
                        onClick={() => {
                          SetContext(product?.id)
                        }}
                        sx={{
                          backgroundColor: `white`,
                          border: `1px solid`,
                          borderColor: theme.palette.grey[400],
                          boxShadow: `0px 2px 6px 0px rgba(30,30,30,0.12)`,
                          borderRadius: `16px`,
                          fontSize: `16px`,
                          minHeight: 50,
                          padding: `5px`,
                          width: `100%`,
                          position: `relative`,
                          boxSizing: `border-box`,
                          color: theme.palette.text.primary,
                          display: `flex`,
                          mb: 2,
                          fontFamily: `Nunito`,
                        }}
                      >
                        {product.name}
                      </ButtonBase>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2}>{t(`No results found`)}</TableCell>
                    </TableRow>
                  )}
                </Box>
              )}
            </Box>
          ) : (
            <Box>
              <Favorites />
            </Box>
          )}
          <Box>
            <BtnNext
              onClick={moveNext}
              style={{ width: `100%`, borderRadius: `32px`, height: `48px` }}
              label={`Diagnostic`}
            />
          </Box>
        </Box>
      </Layout>
    </Box>
  )
}
