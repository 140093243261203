import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import tempo from "../locales/tempo.json"
import od from "../locales/od.json"

export default i18n.use(initReactI18next).init({
  resources: {
    tempo: {
      translation: tempo,
    },
    en: {
      translation: od,
    },
  },
  lng: `tempo`,
  fallbackLng: `tempo`,
  debug: true,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    bindI18n: `languageChanged`,
    bindI18nStore: ``,
    transEmptyNodeValue: ``,
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: [`br`, `strong`, `i`],
    useSuspense: true,
  },
})
