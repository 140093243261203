import { MouseEventHandler } from "react"
import { Button, CircularProgress } from "@mui/material"
import { useTranslation } from "react-i18next"

export interface BtnNextI {
  label?: string | any
  label2?: string | any
  disabled?: boolean
  href?: string
  className?: string
  onClick?: MouseEventHandler
  style?: any
  loading?: boolean
}

export function BtnNext({ disabled, label, label2, href, className, onClick, style, loading }: BtnNextI) {
  const { t } = useTranslation()

  return (
    <Button
      variant={`contained`}
      color={`primary`}
      href={href}
      onClick={onClick}
      style={style}
      className={className}
      type={`submit`}
      disabled={loading || disabled}
      endIcon={loading ? <CircularProgress size={20} /> : undefined}
    >
      {label != null ? label : t(`global.next`)}
      {label2 != null ? label2 : null}
    </Button>
  )
}
