// ----------------------------------------------------------------------

export function Button(theme) {
  return {
    MuiButton: {
      backgroundColor: `grey`,
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: `none`,
          },
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          "&:hover": {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          backgroundColor: theme.palette.primary.main,
          color: `white`,
          border: `2px solid`,
          borderColor: theme.palette.primary.main,
          borderRadius: `35px`,
          fontSize: `16px`,
          lineHeight: `1.11rem`,
          width: `100%`,
          padding: `12px 0`,
          fontWeight: theme.typography.fontWeightBold,
          "&:hover": {
            color: theme.palette.primary.main,
            backgroundColor: `white`,
          },
          "&.Mui-disabled": {
            color: theme.palette.grey[700],
            backgroundColor: theme.palette.grey[400],
            borderColor: theme.palette.grey[400],
          },
        },
        containedSecondary: {
          backgroundColor: `white`,
          boxShadow: `none`,
          color: theme.palette.grey[800],
          border: `none`,
          fontSize: `12px`,
          fontFamily: `Nunito`,
          fontWeight: `400`,
          lineHeight: `1rem`,
          width: `auto`,
          minWidth: 64,
          padding: `2px 10px 2px 0`,
          textDecoration: `none`,
          textAlign: `right`,
          justifyContent: `center`,
          alignItems: `center`,
          "&:hover": {
            color: `white`,
            backgroundColor: theme.palette.grey[800],
          },
        },

        // containedPrimary: {
        //   backgroundColor: 'white',
        //   boxShadow: 'none',
        //   color: theme.palette.text.primary,
        //   border: '2px solid',
        //   borderColor: theme.palette.grey[400],
        //   borderRadius: '35px',
        //   textTransform: 'uppercase',
        //   fontSize: '18px',
        //   fontWeight: 'bold',
        //   lineHeight: '1.11rem',
        //   width: '230px',
        //   padding: '8px 0',
        //   '&:hover': {
        //     color: theme.palette.text.primary,
        //     backgroundColor: theme.palette.grey[400]
        //   },
        // },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  }
}
