// material
import { useTheme } from "@mui/material/styles"
import MuiGlobalStyles from "@mui/material/GlobalStyles"

// ----------------------------------------------------------------------

export function GlobalStyles() {
  const theme = useTheme()

  return (
    <MuiGlobalStyles
      styles={{
        "*": {
          margin: 0,
          padding: 0,
          boxSizing: `border-box`,
        },
        html: {
          width: `100%`,
          height: `100%`,
          margin: 0,
          padding: 0,
          WebkitOverflowScrolling: `touch`,
          backgroundColor: `#ffffff`,
        },
        body: {
          width: `100%`,
          height: `100%`,
          margin: 0,
          padding: 0,
          backgroundColor: `#ffffff`,
        },
        "#root": {
          width: `100%`,
          // height: '100%'
        },
        a: {
          color: theme.palette.text.primary,
        },
        // h3: {
        //   color :'red',
        // },
        input: {
          "&[type=number]": {
            MozAppearance: `textfield`,
            "&::-webkit-outer-spin-button": {
              margin: 0,
              WebkitAppearance: `none`,
            },
            "&::-webkit-inner-spin-button": {
              margin: 0,
              WebkitAppearance: `none`,
            },
          },
        },
        textarea: {
          "&::-webkit-input-placeholder": {
            color: theme.palette.text.disabled,
          },
          "&::-moz-placeholder": {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
          "&:-ms-input-placeholder": {
            color: theme.palette.text.disabled,
          },
          "&::placeholder": {
            color: theme.palette.text.disabled,
          },
        },

        img: { display: `block` },

        // Lazy Load Img
        ".blur-up": {
          WebkitFilter: `blur(5px)`,
          filter: `blur(5px)`,
          transition: `filter 400ms, -webkit-filter 400ms`,
        },
        ".blur-up.lazyloaded ": {
          WebkitFilter: `blur(0)`,
          filter: `blur(0)`,
        },
        // h3: {
        //   color: 'red',
        // },
      }}
    />
  )
}
