import { useRoutes } from "react-router-dom"
import { Views } from "./views"
import { AppProvider, ConfigProvider, IFunnelConfig } from "@homeserve/od-funnel-lib"
import { ThemeConfig } from "./theme"
import "./css/app.css"
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook"

export function App() {
  const routes = useRoutes([{ path: `/*`, element: <Views /> }])
  const gtmParams = { id: `GTM-TF3DHQ4` }
  const config: IFunnelConfig = {
    orderServiceUrl: process.env.REACT_APP_SERVICE_ORDER_URL || ``,
    catalogServiceUrl: process.env.REACT_APP_SERVICE_CATALOG_URL || ``,
    voucherServiceUrl: process.env.REACT_APP_SERVICE_VOUCHER_URL || ``,
    tenantServiceUrl: process.env.REACT_APP_SERVICE_TENANT_URL || ``,
    authUrl: process.env.REACT_APP_AUTH_URL || ``,
    clientId: process.env.REACT_APP_CLIENT_ID || ``,
    clientSecret: process.env.REACT_APP_CLIENT_SECRET || ``,
    addressServiceUrl: process.env.REACT_APP_SERVICE_ADDRESS_URL || ` `,
    affiliationCode: process.env.REACT_APP_AFFILIATE_CODE || ``,
    partnerTreeId: process.env.REACT_APP_PARTNER_TREE_ID || ``,
    partnerName: `Tempo`,
    tempoUrl: process.env.REACT_APP_TEMPO_URL || ``,
  }

  return (
    <ThemeConfig>
      <GTMProvider state={gtmParams}>
        <ConfigProvider config={config}>
          <AppProvider>{routes}</AppProvider>
        </ConfigProvider>
      </GTMProvider>
    </ThemeConfig>
  )
}
