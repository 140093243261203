// ----------------------------------------------------------------------

export function Card(theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          // boxShadow: theme.customShadows.z1,
          border: `1px solid`,
          borderColor: theme.palette.grey[400],
          borderRadius: theme.shape.borderRadiusMd,
          position: `relative`,
          zIndex: 0, // Fix Safari overflow: hidden with border radius
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: `h6` },
        subheaderTypographyProps: { variant: `body2` },
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0),
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
        },
      },
    },
  }
}
