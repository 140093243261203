import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { AppContext, Requalification, useProduct, Loader, useConfig } from "@homeserve/od-funnel-lib"
import { Box, Card, CardContent, CardHeader, Divider, Stack, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"

import { BtnBack, BtnNext, Layout } from "../../components"
import { useTheme } from "@mui/material/styles"
import { AddCircleOutlineOutlined, ArrowBackIos, RemoveCircleOutlineOutlined } from "@mui/icons-material"
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"
export function Product() {
  const theme = useTheme()
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo({ behavior: `smooth`, top: 0 })
  }, [])
  function MoveBack() {
    const lastDiag = window.localStorage.getItem(`lastDiagnosticNavigation`)
    if (lastDiag) {
      navigate(lastDiag)
    } else {
      navigate(`/`)
    }
  }

  const { t } = useTranslation()
  const { state } = useContext(AppContext)
  const config = useConfig()
  const sendDataToGTM = useGTMDispatch()

  useEffect(() => {
    return sendDataToGTM({
      event: `productDetail`,
      PageType: `Page Produit`,
      category: `DL - Tunnel`,
      visitorPostalCode: state.customer.billingAddress?.postCode,
      sku: `DL - ` + state.product.id,
      name: `DL - ` + state.product.name,
      price: `DL - ` + state.product.price,
      quantity: 1,
      brand: `DL - ` + config.partnerName,
    })
    // eslint-disable-next-line
  }, [state.product]);

  useProduct({ productId: state.product.id }).catch((e: any) => {
    console.error(`impossible to get product`, e)
  })

  return (
    <>
      <Helmet>
        <title>{t(`seoTitle.product`) + ` ` + state.product.id + ` - Tempo`}</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      {!state.product.name ? (
        <Box
          sx={{
            height: `100vh`,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            backgroundColor: `white`,
          }}
        >
          <Loader primaryColor={theme.palette.primary.main} secondaryColor={theme.palette.secondary.main} />
        </Box>
      ) : (
        <>
          <Stack pt={2} pl={2} mb={0} direction="row" spacing={2} justifyContent={`space-between`} alignItems={`end`}>
            <BtnBack onClick={MoveBack} startIcon={<ArrowBackIos />} />
          </Stack>
          {state.product.name && (
            <Layout>
              <Box sx={{ pb: 8 }}>
                <Grid container spacing={{ xs: 0, md: 2 }}>
                  <Grid xs={12} md={7}>
                    <Box>
                      <Typography variant={`h1`} sx={{ fontSize: 24 }}>
                        {state.product.name}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={{ xs: 0, md: 2 }}>
                  <Grid xs={12} md={7} sx={{ pb: 1 }}>
                    <Box
                      sx={{ justifyContent: `space-between`, alignItems: `baseline` }}
                      display={{ xs: `flex`, md: `none` }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: `bold` }}
                        fontSize={{ md: `20px`, sm: `19px`, xs: `14px` }}
                      >
                        {t(`product.newRate`)}
                      </Typography>
                      <Typography variant="body1">
                        <span style={{ fontWeight: `bold`, fontSize: 30, color: theme.palette.primary.main }}>
                          {state.product.price} €
                        </span>
                        <span style={{ fontWeight: `bold`, fontSize: 14, color: theme.palette.primary.main }}>
                          {t(`product.vatIncluded`)}
                        </span>
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      display={{ md: `none` }}
                      sx={{ fontStyle: `italic`, position: `relative`, top: `-10px` }}
                    >
                      {t(`product.excludingDiscount`)}
                    </Typography>
                    <Box display={{ md: `none` }} sx={{ mt: 1, mb: 3 }}>
                      <Divider />
                    </Box>
                    <Box sx={{ mb: 1, mt: 2, textAlign: `left` }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: `bold`, marginBottom: `10px` }}
                        fontSize={{ md: `20px`, sm: `19px`, xs: `14px` }}
                      >
                        {t(`product.jobDetails`)}
                      </Typography>
                      <Card>
                        <CardContent>
                          <Typography
                            variant="body2"
                            dangerouslySetInnerHTML={{
                              __html: state.product.traderInstructions,
                            }}
                          />
                        </CardContent>
                      </Card>
                      {state.product.inclusion && (
                        <Card sx={{ my: 2 }}>
                          <CardHeader
                            title={`Inclus dans la prestation`}
                            sx={{
                              backgroundColor: theme.palette.grey[200],
                              py: 1,
                              px: 2,
                            }}
                            titleTypographyProps={{
                              variant: `h5`,
                              fontSize: 14,
                            }}
                            avatar={
                              <AddCircleOutlineOutlined
                                sx={{
                                  color: theme.palette.success.main,
                                  width: 32,
                                  height: 32,
                                }}
                              />
                            }
                          />
                          <CardContent sx={{ pl: 5 }}>
                            <Typography
                              variant={`body2`}
                              dangerouslySetInnerHTML={{
                                __html: state.product.inclusion,
                              }}
                            />
                          </CardContent>
                        </Card>
                      )}
                      {state.product.exclusion && (
                        <Card sx={{ mb: 3 }}>
                          <CardHeader
                            title={`Non inclus dans la prestation`}
                            sx={{
                              backgroundColor: theme.palette.grey[200],
                              py: 1,
                              px: 2,
                            }}
                            titleTypographyProps={{
                              variant: `h5`,
                              fontSize: 14,
                            }}
                            avatar={
                              <RemoveCircleOutlineOutlined
                                sx={{
                                  color: theme.palette.error.main,
                                  width: 32,
                                  height: 32,
                                }}
                              />
                            }
                          />
                          <CardContent sx={{ pl: 5 }}>
                            <Typography
                              variant={`body2`}
                              dangerouslySetInnerHTML={{
                                __html: state.product.exclusion,
                              }}
                            />
                          </CardContent>
                        </Card>
                      )}
                    </Box>
                  </Grid>
                  <Grid xs={12} md={5} display={{ xs: `none`, md: `block` }}>
                    <Box display={{ xs: `none`, md: `block` }} sx={{ mt: 5 }}>
                      <Box sx={{ display: `flex`, justifyContent: `space-between`, alignItems: `baseline` }}>
                        <Typography variant="body1" sx={{ fontWeight: `bold` }} fontSize={20}>
                          {t(`product.newRate`)}
                        </Typography>
                        <Typography variant="body1">
                          <span style={{ fontWeight: `bold`, fontSize: 30, color: theme.palette.primary.main }}>
                            {state.product.price} €
                          </span>
                          <span style={{ fontWeight: `bold`, fontSize: 14, color: theme.palette.primary.main }}>
                            {t(`product.vatIncluded`)}
                          </span>
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ fontStyle: `italic`, position: `relative`, top: `-10px` }}>
                        {t(`product.excludingDiscount`)}
                      </Typography>
                      <Box sx={{ mt: 2, mb: 4 }}>
                        <Divider />
                      </Box>
                      <Requalification
                        btnNext={BtnNext}
                        stepperColor={theme.palette.primary.main}
                        stepperColorSecondary={theme.palette.secondary.main}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Layout>
          )}
          {state.product.name && (
            <Box
              sx={{
                position: `fixed`,
                bottom: 0,
                width: `100%`,
                minWidth: `100%`,
                backgroundColor: `white`,
                py: 2,
                px: 2,
                justifyContent: `center`,
              }}
              display={{ xs: `flex`, md: `none` }}
            >
              <Requalification
                btnNext={BtnNext}
                stepperColor={theme.palette.primary.main}
                stepperColorSecondary={theme.palette.secondary.main}
              />
            </Box>
          )}
        </>
      )}
    </>
  )
}
